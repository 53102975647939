<template>
  <!-- 商品列表页面 -->
  <div class="container">
    <a-row>
      <a-col :span="24" class="right">
        <div class="right-content">
          <!-- 筛选 -->
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="40">
                <a-col :md="6" :sm="24">
                  <!-- 筛选 -->
                  <a-form-item>
                    <a-select v-model="queryParam.type" allow-clear placeholder="请选择状态">
                      <a-select-option :value="1">出售中</a-select-option>
                      <a-select-option :value="2">已售罄</a-select-option>
                      <a-icon slot="suffixIcon" type="search" />
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="6" :sm="24">
                  <!-- 商品分类 -->
                  <a-form-item>
                    <a-tree-select
                      v-model="queryParam.goodsCategoryId"
                      style="width: 100%"
                      :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                      placeholder="请选择所属分类"
                      allow-clear
                    >
                      <a-tree-select-node
                        v-for="parentItem in classList"
                        :key="parentItem.id"
                        :value="parentItem.id"
                        :title="parentItem.name"
                        :selectable="false"
                      >
                        <a-tree-select-node
                          v-for="item in parentItem.childrenList"
                          :key="item.id"
                          :selectable="true"
                          :value="item.id"
                          :title="item.name"
                        />
                      </a-tree-select-node>
                      <a-icon slot="suffixIcon" type="search" />
                    </a-tree-select>
                  </a-form-item>
                </a-col>
                <a-col :md="6" :sm="24">
                  <!-- 商品搜索 -->
                  <a-form-item label="商品搜索">
                    <a-input-search
                      placeholder="请输入商品名称、关键字"
                      v-model="queryParam.keywords"
                      @pressEnter="searchTableData"
                    />
                  </a-form-item>
                </a-col>
                <a-col :md="6" :sm="24">
                  <a-button type="primary" @click="searchTableData">搜索</a-button>
                  <a-button
                    style="margin-left: 8px"
                    @click="
                      () => {
                        queryParam = {}
                        searchTableData()
                      }
                    "
                    >重置</a-button
                  >
                </a-col>
              </a-row>
            </a-form>
          </div>
          <!-- 添加商品按钮 -->
          <div class="table-operator">
            <a-button type="primary" @click="addCommodity">添加商品</a-button>
            <a-button type="primary" @click="batchSellOutCommodity"> 批量上下架/售罄</a-button>
            <a-button type="primary" @click="batchCopyCommodity"> 批量复制商品</a-button>
          </div>
          <!-- 表格 -->
          <a-table
            :row-selection="rowSelection"
            rowKey="id"
            :columns="columns"
            :data-source="data"
            :scroll="{ x: 1300, y: 800 }"
            :loading="loading"
            :pagination="false"
          >
            <!-- 商品图片插槽 -->
            <span slot="commodityImg" slot-scope="text, record">
              <viewer :images="[record.coverImage]">
                <img
                  :src="record.coverImage"
                  style="width: 50px; height: 50px; border: 0.5px solid #ccc; padding: 5px; margin: 3px"
                  alt=""
                />
              </viewer>
            </span>
            <!-- 规格类型插槽 -->
            <span slot="speType" slot-scope="text">
              {{ text == 1 ? '单规格' : text == 2 ? '多规格' : '' }}
            </span>
            <!-- 状态插槽 -->
            <span slot="delFlag" slot-scope="text, record" @click="clickSwitch(record)">
              <a-switch
                checked-children="上架"
                un-checked-children="下架"
                v-model="record.checked"
                @change="changeSwitch"
              />
            </span>
            <!-- 是否需要开发票        -->
            <span slot="isInvoice" slot-scope="text">
              <a-tag color="red" v-if="+text === 1"> 否 </a-tag>

              <a-tag color="green" v-else> 是 </a-tag>
            </span>
            <!-- 平台封禁 -->
            <span slot="isLowerShelf" slot-scope="text, record">
              <a-tag
                :color="text == 1 ? 'red' : 'green'"
                v-if="userInfo.roles.length > 0 && userInfo.roles[0].code != 'MANAGER_SYSTEM'"
              >
                {{ text == 1 ? '否' : '是' }}
              </a-tag>
              <a-switch
                v-else
                checked-children="开启"
                un-checked-children="关闭"
                v-model="record.banned"
                @change="changeBanned($event, record)"
              />
            </span>
            <!-- 操作插槽 -->
            <span slot="action" slot-scope="text, record">
              <a @click="showModal1(record)" v-if="record.speType == 2">绑定规格</a>
              <a-divider type="vertical" v-if="record.speType == 2" />
              <router-link :to="{ path: '/setPrice', query: { id: record.id } }" v-if="record.speType == 2"
                >设置价格
              </router-link>
              <a-divider type="vertical" v-if="record.speType == 2" />
              <router-link :to="{ path: `/editCommodity`, query: { id: record.id, areaId: areaId } }">编辑</router-link>
              <a-divider type="vertical" />
              <a @click="$refs.detailForm.detail(record)"> 详情 </a>
              <a-divider type="vertical" />
              <a-popconfirm placement="topRight" title="确认删除？" @confirm="() => sysItemDelete(record)">
                <a>删除</a>
              </a-popconfirm>
              <a-divider type="vertical" />
            </span>
          </a-table>
          <!-- 分页组件 -->
          <!-- 分页组件 -->
          <div style="margin-top: 20px; display: flex; flex-direction: row-reverse">
            <a-pagination
              :pageSize="page.pageSize"
              :current="page.pageNo"
              show-size-changer
              :page-size-options="['10', '20', '50', '100']"
              show-quick-jumper
              :total="total"
              :show-total="(total) => `共 ${total} 条`"
              @change="pageChange"
              @showSizeChange="sizeChange"
            ></a-pagination>
          </div>
        </div>
      </a-col>
    </a-row>
    <a-modal
      :width="1200"
      title="绑定规格"
      :visible="visible1"
      @cancel="handleCancel1"
      cancelText="关闭"
      :confirm-loading="confirmLoading1"
      :footer="null"
    >
      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="$refs.addSpecForm.add(currentItem)">添加</a-button>
      </div>
      <a-table :columns="specColumns" :data-source="specDatas" :scroll="{ x: 800 }">
        <span slot="action" slot-scope="text, record">
          <a @click="showModal2(record)">添加规格属性</a>
          <a-divider type="vertical" />
          <a-popconfirm placement="topRight" title="确定删除该规格?" @confirm="() => confirmDeleteSpec(record)">
            <a>删除</a>
          </a-popconfirm>
        </span>
      </a-table>
    </a-modal>
    <a-modal
      :width="1200"
      title="规格属性"
      :visible="visible2"
      :confirm-loading="confirmLoading2"
      :footer="null"
      @cancel="handleCancel2"
    >
      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="$refs.addSepcvalueForm.add(currentSpecItem, currentItem)">
          添加</a-button
        >
      </div>
      <a-table :columns="specValueColumns" :data-source="specValueDatas" :pagination="false" :scroll="{ x: 800 }">
        <a slot="name" slot-scope="text">{{ text }}</a>
        <span slot="customTitle"> <a-icon type="smile-o" /> Name </span>
        <span slot="action" slot-scope="text, record">
          <a-popconfirm
            placement="topRight"
            title="确定删除该规格属性?"
            @confirm="() => confirmDeleteSpecValue(record)"
          >
            <a>删除</a>
          </a-popconfirm>
        </span>
      </a-table>
    </a-modal>
    <add-spec-form ref="addSpecForm" :areaId="areaId" @ok="handleSpecOk" />
    <add-specvalue-form ref="addSepcvalueForm" @ok="handleSpecvalueOk" />
    <detail-form ref="detailForm" />
    <BatchCopyCommodity ref="batchCopyCommodity" :areaId="areaId" @ok="refreshList"></BatchCopyCommodity>
    <BatchSellOutCommodity ref="batchSellOutCommodity" :areaId="areaId" @ok="refreshList"></BatchSellOutCommodity>
  </div>
</template>

<script>
import {
  getCommodityList,
  querySpecListByCommodityId,
  querySpecValueListBySpcId,
  commodityDelete,
  deleteGoodsSpecValue,
  deleteGoodsSpec,
  platformLowerShelfGoods,
} from '@/api/modular/mallLiving/commodity/commodityList'

import AddSpecForm from './addSpecForm.vue'
import AddSpecvalueForm from './addSpecvalueForm'
import detailForm from './detailForm.vue'

import { findCategoryListByStore } from '@/api/modular/mallLiving/commodity/commodityClass' //查询 分类及二级分类
import BatchCopyCommodity from './batchCopyCommodity.vue'
import BatchSellOutCommodity from './batchSellOutCommodity.vue'

const columns = [
  {
    title: '序号',
    align: 'center',
    width: '80px',
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: '商品名称',
    dataIndex: 'name',
    key: 'name',
    width: '260px',
  },
  {
    title: '一级分类',
    dataIndex: 'parentGoodsCategoryName',
    key: 'parentGoodsCategoryName',
    align: 'center',
    width: '150px',
  },
  {
    title: '二级分类',
    dataIndex: 'goodsCategoryName',
    key: 'goodsCategoryName',
    align: 'center',
    width: '150px',
  },
  {
    title: '封面图',
    dataIndex: 'coverImage',
    key: 'coverImage',
    align: 'center',
    scopedSlots: {
      customRender: 'commodityImg',
    },
    width: '100px',
  },
  {
    title: '市场价',
    key: 'marketPrice',
    dataIndex: 'marketPrice',
    align: 'center',
    width: '160px',
  },
  {
    title: '销售量',
    key: 'sales',
    dataIndex: 'sales',
    align: 'center',
    width: '160px',
  },
  {
    title: '规格类型',
    key: 'speType',
    dataIndex: 'speType',
    align: 'center',
    scopedSlots: {
      customRender: 'speType',
    },
    width: '160px',
  },
  {
    title: '库存',
    key: 'stock',
    dataIndex: 'stock',
    align: 'center',
    width: '86px',
  },

  {
    title: '状态',
    key: 'delFlag',
    dataIndex: 'delFlag',
    scopedSlots: {
      customRender: 'delFlag',
    },
    align: 'center',
    width: '160px',
  },

  {
    title: '平台封禁',
    key: 'isLowerShelf',
    dataIndex: 'isLowerShelf',
    scopedSlots: {
      customRender: 'isLowerShelf',
    },
    align: 'center',
    width: '160px',
  },
  {
    title: '操作',
    align: 'center',
    dataIndex: 'action',
    width: '240px',
    key: 'action',
    fixed: 'right',
    scopedSlots: {
      customRender: 'action',
    },
  },
]

export default {
  components: {
    BatchSellOutCommodity,
    BatchCopyCommodity,
    AddSpecForm,
    AddSpecvalueForm,
    detailForm,
  },
  data() {
    return {
      visible1: false,
      confirmLoading1: false,
      columns,
      currentItem: null,
      currentSpecItem: null,
      data: [],
      loading: false,
      confirmLoading2: false,
      visible2: false,
      page: {
        pageNo: 2,
        pageSize: 10,
      },
      total: 0,
      classList: [], //顶部商品分类筛选数
      queryParam: {
        commodityClass: undefined, //商品分类
        commoditySearch: undefined, //商品搜索
      },
      specPage: {
        pageNumber: 1,
        pageSize: 10,
      },
      specValuePage: {
        pageNumber: 1,
        pageSize: 10,
      },
      specTotal: 0,
      specValueTotal: 0,
      specValueColumns: [
        {
          title: '规格属性名称',
          dataIndex: 'specValueName',
          align: 'center',
          width: 200,
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          width: 200,
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
      specColumns: [
        {
          title: '规格名称',
          dataIndex: 'specName',
          align: 'center',
          width: 200,
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          width: 200,
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
      specDatas: [],
      specValueDatas: [],
      areaId: '',
      selectedRowKeys: [],
      selectRowId: [],
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo
    },
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectRowData) => {
          this.selectedRowKeys = selectedRowKeys
          this.selectRowId = selectRowData.map((item) => {
            return item.id
          })
        },
      }
    },
  },
  mounted() {
    console.log('this.$store.getters.userInfo', this.userInfo)
    this.areaId = this.$route.query.areaId
    this.initTableData()

    this.getCommodityClassList() //获取下拉列表分类信息
  },
  methods: {
    batchCopyCommodity() {
      if (!this.selectRowId.length) {
        return this.$message.info('请选择需要复制的商品')
      }
      this.$refs.batchCopyCommodity.show(this.selectRowId)
    },
    batchSellOutCommodity() {
      if (!this.selectRowId.length) {
        return this.$message.info('请选择需要批量上下架/售罄的商品')
      }
      this.$refs.batchSellOutCommodity.show(this.selectRowId)
    },
    refreshList() {
      this.selectRowId = []
      this.selectedRowKeys = []
      this.getList()
    },
    specPageChange(pageNo, pageSize) {
      this.specPage.pageNumber = pageNo
      this.specPage.pageSize = pageSize
      this.querySpecvalueListBySpecId()
    },
    sizeChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    confirmDeleteSpecValue(record) {
      deleteGoodsSpecValue({
        id: record.goodsSpecValueId,
      }).then((res) => {
        if (res.success) {
          this.$message.success('商品规格属性删除成功')
          this.querySpecvalueListBySpecId()
        }
      })
    },
    confirmDeleteSpec(record) {
      deleteGoodsSpec({
        id: record.goodsSpecId,
      }).then((res) => {
        if (res.success) {
          this.$message.success('商品规格删除成功')
          this.findCommoditySpecList()
        }
      })
    },
    showModal2(record) {
      this.currentSpecItem = record
      this.querySpecvalueListBySpecId()
      this.visible2 = true
    },

    handleCancel1() {
      this.visible1 = false
    },

    handleCancel2(e) {
      this.visible2 = false
    },

    async querySpecvalueListBySpecId() {
      const data = await querySpecValueListBySpcId({
        goodsSpecId: this.currentSpecItem.goodsSpecId,
      })
      if (data.code == 200) {
        this.specValueDatas = data.data
      }
    },
    handleSpecvalueOk() {
      this.specValuePage.pageNumber = 1
      this.querySpecvalueListBySpecId()
    },
    handleSpecOk() {
      this.specPage.pageNumber = 1
      this.findCommoditySpecList()
    },
    showModal1(record) {
      this.currentItem = record
      this.specValuePage.pageNumber = 1
      this.findCommoditySpecList()
      this.visible1 = true
    },

    async findCommoditySpecList() {
      var params = {
        page: this.specPage,
        queryParam: this.currentItem.id,
      }
      const data = await querySpecListByCommodityId({
        goodsId: this.currentItem.id,
      })
      if (data.code == 200) {
        this.specDatas = data.data
      }
    },
    // 获取商品分类及其子列表信息
    getCommodityClassList() {
      findCategoryListByStore().then((res) => {
        //处理分类数据
        this.classList = res.data
      })
    },
    //切换页面
    pageChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    initTableData() {
      this.page.pageNo = 1
      this.getList()
    },
    searchTableData() {
      this.initTableData()
    },
    getList() {
      this.loading = true
      var params = {
        page: this.page,
        queryParam: { ...this.queryParam, areaId: this.areaId },
      }
      getCommodityList(params)
        .then((res) => {
          if (res.success) {
            this.loading = false
            this.data = res.data.rows
            this.total = res.data.totalRows
            this.data.forEach((item) => {
              item.checked = item.delFlag == 1
              item.banned = +item.isLowerShelf !== 1
            })
          }
        })
        .finally((res) => {
          setTimeout(() => {
            this.loading = false
          }, 5000)
        })
    },
    // 删除
    sysItemDelete(record) {
      this.recordItem = record
      this.disableCommodityCate(2) //2:删除
    },
    changeBanned(event, record) {
      let content = event ? '确定下架当前商品？' : '确定取消下架当前商品？'
      let msgContent = event ? '下架当前商品成功' : '取消下架当前商品成功'
      let errorContent = event ? '下架当前商品失败' : '取消下架当前商品失败'
      this.$confirm({
        title: '提示',
        content,
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          console.log('OK')
          platformLowerShelfGoods({ goodsId: record.id, isLowerShelf: event ? 2 : 1 })
            .then((res) => {
              if (res.success) {
                this.$message.success(msgContent)
                this.getList()
              }
            })
            .catch((error) => {
              this.$message.error(error.message || errorContent)
            })
        },
        onCancel() {
          console.log('Cancel')
        },
      })
    },
    clickSwitch(record) {
      this.recordItem = record
    },
    changeSwitch(e) {
      let that = this
      let delFlag = e ? 1 : 3
      setTimeout(function () {
        that.disableCommodityCate(delFlag)
      }, 300)
    },
    async disableCommodityCate(delFlag) {
      let params = {
        delFlag: delFlag,
        id: this.recordItem.id,
      }
      let data = await commodityDelete(params)
      var text = delFlag == 1 ? '上架' : delFlag == 2 ? '删除' : '下架'
      if (data.code == 200) {
        this.$message.success(this.recordItem.name + ' 已' + text)
        this.getList()
      }
    },
    //添加商品页面路由跳转
    addCommodity() {
      this.$router.push({
        path: `/addCommodity?areaId=${this.areaId}`,
      })
    },
  },
}
</script>

<style lang="less" scoped>
// @import url(); 引入公共css
.container {
  min-width: 1000px;
}

.title {
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  font-weight: bold;
}

.right {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 100);
}

.right-content {
  box-sizing: border-box;
  padding: 10px;
}

.icon {
  font-size: 20px;
  margin: 0 5px;
}

img {
  width: 30px;
  height: 30px;
}
</style>
