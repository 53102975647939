<template>
  <a-modal
      title="批量上下架/售罄商品"
      width="50%"
      :visible="visible"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      @ok="handleAuditBatch"
      @cancel="handleCancel"
  >
    <a-form :form="form">

      <a-form-item
          label="区域"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          has-feedback
      >
        <a-select
            mode="multiple"
            v-decorator="['areaIdList', { rules: [{ required: true, message: '请选择区域！' }],initialValue:[areaId] }]"
            allow-clear
            placeholder="请选择区域"
        >
          <a-select-option v-for="(item, index) in dataList" :key="index" :value="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item
          label="类型"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          has-feedback
      >
        <a-radio-group  v-decorator="['updateType', { rules: [{ required: true, message: '请选择类型！' }], initialValue:1}]">
          <a-radio :value="1">
            上架
          </a-radio>
          <a-radio :value="2">
            下架
          </a-radio>
          <a-radio :value="3">
            售罄
          </a-radio>
        </a-radio-group>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import {areaPage,} from "@/api/modular/mallLiving/area";
import { updateInfoBatch} from "@/api/modular/mallLiving/commodity/commodityList";

export default {
  name: 'batchSellOutCommodity',
  props: {
    areaId: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      visible: false, //modal框显示状态
      confirmLoading: false,
      batchData: {},   //父组件传过来的数据
      form: this.$form.createForm(this),
      labelCol: {
        xs: {span: 24},
        sm: {span: 3},
      },
      wrapperCol: {
        xs: {span: 24},
        sm: {span: 14},
      },
      goodsIdList: [],
      dataList: []
    }
  },
  mounted() {
    this.getAreaList()
  },
  methods: {
    //初始化方法
    show(goodsIdList) {
      this.visible = true
      this.goodsIdList = goodsIdList
    },
    getAreaList() {
      let query = {
        queryParam: {},
        page: {
          pageNo: 1,
          pageSize: 1000000,
        },
      }
      areaPage(query).then((res) => {
        if (res.success) {
          this.dataList = res.data.rows
        }
      })
    },
    // 点击遮罩层或右上角叉或取消按钮的操作
    handleCancel() {
      this.visible = false
      this.confirmLoading = false
      this.$nextTick(() => {
        this.form.setFieldsValue({
          storeCustomerId: ''
        })
        this.customerId = ''
      })

    },
    //批量审核
    handleAuditBatch() {
      this.form.validateFields((errors, values) => {
        if (!errors) {
          updateInfoBatch({...values, goodsIdList: this.goodsIdList,}).then((res) => {
            if (res.success) {
              let {updateType}=values
              if(updateType==1){
                this.$message.success('批量上架商品成功')
              }else if(updateType==2){
                this.$message.success('批量下架商品成功')
              }else if(updateType==3){
                this.$message.success('批量售罄商品成功')
              }
              this.$emit("ok")
              this.handleCancel()
            } else {
              this.$message.error("操作失败")
              setTimeout(() => {
                this.confirmLoading = false
              }, 600)
            }
          })
        }
      })

    }
  }
}
</script>
<style lang="less" scoped>
.mytable {
  margin-bottom: 70px;
  border-collapse: collapse;
  width: 100%;
  height: 250px;

  .title {
    background: rgb(207, 248, 248);
    width: 20%
  }

  td {
    border: 2px solid rgb(228, 225, 225);
    padding-left: 7px;
    font-size: 15px;
  }
}

img {
  width: 100px;
  height: 50px;
}

.flex {
  display: flex;
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>